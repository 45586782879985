// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

@import 'colors';
@import "@ng-select/ng-select/themes/material.theme.css";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$matelex-primary: mat.define-palette(mat.$indigo-palette);
$matelex-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$matelex-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$matelex-theme: mat.define-light-theme(
  (
    color: (
      primary: $matelex-primary,
      accent: $matelex-accent,
      warn: $matelex-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($matelex-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: 'Ubuntu', sans-serif;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
}
body {
  background-color: $general-light-blue-bg;
  margin: 0;
  font-family: 'Ubuntu', sans-serif, Roboto, 'Helvetica Neue', sans-serif !important;
  @media (max-width: 1400px) {
    overflow-x: hidden;
  }
}

.auto-width {
  .mat-form-field {
    width: auto !important;
  }
  .mat-select-value {
    max-width: 100%;
    width: auto;
  }
}

.search-wrapper {
  .mat-form-field {
    input {
      &.mat-input-element {
        margin-bottom: 10px;
      }
    }
    .mat-form-field-label-wrapper {
      top: -1.05em !important;
    }
  }
  .mat-focused {
    .mat-form-field-label {
      top: -2.2em !important;
    }
  }
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
  }
}

h1 {
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.06px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}

.light-grey {
  color: $light-grey-color;
}

.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px !important;
}

.no-border {
  border: 0 !important;
}

app-dashboard,
app-dni-list,
app-user-list,
app-customer-location-list,
app-dni-details {
  width: 100%;
}

// BUTTON
button {
  &:disabled {
    pointer-events: unset !important;
    cursor: not-allowed !important;
  }
}

.mat-raised-button {
  padding: 0 16px 0 12px;
  &.mat-primary {
    background-color: $indigo-color !important;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13);
    .mat-icon {
      margin-right: 5px;
      font-size: 20px;
      line-height: 23px;
    }
  }
  &.mat-basic {
    height: 36px;
    background-color: $light-grey-bg;
  }
  &.mat-success {
    background-color: #4caf50 !important;
    color: #fff !important;
  }
}

.mat-select-panel-wrap {
  margin-top: 30px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mat-form-field-required-marker {
  margin-left: 0 !important;
}

.mat-form-field-underline {
  display: none !important;
}

.btn-secondary {
  background-color: $indigo-color;
  color: #fff;
}

.content-header {
  display: flex;
  justify-content: space-between;
}

.mat-chip-remove {
  margin-left: 5px;
  margin-right: 3px !important;
  margin-top: -8px;
  opacity: 1 !important;
  &.mat-icon {
    color: $light-grey-color !important;
    font-size: 26px !important;
  }
}

// background: 33, 150, 243, 1;

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba(33, 150, 243, 0.5);
    }
    .mat-slide-toggle-thumb {
      background-color: #0f4169;
    }
  }
  .mat-slide-toggle-content {
    font-size: 16px;
    letter-spacing: 0.15px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif !important;
  }
}

// Alerts

.alert-message {
  display: inline-block;
  padding: 0 7px;
  background-color: #eceff1;
  color: $primary-grey-color;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.83px;
  font-family: 'Montserrat', sans-serif !important;
  word-break: keep-all;
  &.danger {
    background-color: #feebee;
    color: $red-color;
  }
  &.warning {
    background-color: #fff3e0;
    color: $orange-color;
  }
  &.success {
    background-color: $light-green-bg;
    color: $green-color;
  }
}
.indicatif .ng-input, .indicatif .ng-value {
  padding: 10px;
}
.phone-container .mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0 !important;
}
// TABLE

.mat-table {
  width: 100%;
  .mat-row {
    height: 70px;
    &:hover {
      background-color: #f4f6f8;
    }
    &.selected {
      background-color: #f4f6f8;
    }
  }
  .mat-header-cell {
    color: $primary-grey-color;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif !important;
  }
  .mat-cell {
    color: $primary-grey-color;
    font-size: 14px;
    line-height: 20px;
    padding-right: 5px !important;
    .custom-icon {
      color: $primary-grey-color;
      font-size: 18px;
      margin-right: 20px;
    }
    .mat-icon {
      color: $primary-grey-color;
      font-size: 18px;
    }
    a {
      text-decoration: none;
      &:hover {
        opacity: 0.9;
      }
    }
    .user-image {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 7px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  th {
    &.mat-header-cell {
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.mat-card {
  box-shadow: 0 1px 2px 0 rgba(63, 63, 68, 0.15) !important;
  .mat-card-title {
    font-family: 'Montserrat', sans-serif !important;
    margin-bottom: 0 !important;
  }
}

.mat-paginator-page-size-label {
  color: $light-grey-color !important;
}
.mat-paginator-icon {
  width: 22px !important;
  fill: $primary-grey-color !important;
}
button[disabled='true'] .mat-paginator-icon {
  fill: $paginator-arrow-disabled-gray !important;
}

.mat-list-item {
  .mat-badge-content {
    top: 14px !important;
    right: 14px !important;
    max-width: 70px;
    width: 35px;
    height: 20px;
    border-radius: 0;
    background-color: #feebee;
    color: $red-color;
    font-family: 'Montserrat', sans-serif !important;
  }
}

.mat-tab-label {
  .mat-badge-content {
    position: relative;
    top: 5px !important;
    right: auto !important;
    margin-left: 10px;
    width: auto !important;
    padding: 0 3px;
    min-width: 14px;
    height: 20px;
    border-radius: 0;
    background-color: #feebee;
    color: $red-color;
    max-width: 270px !important;
    span {
      font-family: 'Montserrat', sans-serif !important;
    }
  }
}
mat-panel-title {
  .mat-badge-content {
    position: relative;
    top: -1px !important;
    right: auto !important;
    margin-left: 10px;
    width: auto !important;
    padding: 0 3px;
    min-width: 14px;
    height: 15px;
    border-radius: 0;
    background-color: #feebee;
    color: $red-color;
  }
}

// MAT TABS
.mat-tab-label-container {
  .mat-tab-label {
    opacity: 1 !important;
    .mat-tab-label-content {
      color: $light-grey-color;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 600 !important;
    }
    &.mat-tab-label-active {
      .mat-tab-label-content {
        color: $turquoise-color;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif !important;
        font-weight: 600 !important;
      }
    }
  }
  .mat-ink-bar {
    background-color: $turquoise-color !important;
  }
}
.mat-tab-label {
  max-width: 270px !important;
}

.content-wrapper {
  padding: 34px 50px;
  width: 100%;
  @media (max-width: 1439px) {
    padding: 34px 5px 34px 25px;
  }
  &.dni-list-content {
    .mat-card {
      padding-right: 10%;
      @media (max-width: 1439px) {
        padding-right: 2% !important;
      }
    }
    @media (max-width: 1439px) {
      width: calc(100% - 260px);
    }
    @media (max-width: 904px) {
      // 991
      width: 94%;
    }
  }
}

.auth-form {
  .mat-form-field {
    &:first-child {
      margin-bottom: 25px;
    }
    .mat-form-field-label {
      span {
        margin-left: 15px;
        color: #546e7a;
        font-size: 16px;
        letter-spacing: 0.1px;
      }
    }
  }
}
.dni-configuration-modal-wrapper {
  .mat-expansion-panel {
    box-shadow: rgba(0, 0, 0, 0) !important;
    .mat-expansion-panel-body {
      padding-right: 70px;
    }
  }
}

.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #0f4169 !important;
}

.mat-tooltip {
  background-color: #546e7a !important;
  color: #fff !important;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.mat-radio-container {
  .mat-ripple-element {
    background-color: #0f4169 !important;
    &.mat-radio-persistent-ripple {
      background-color: #0f4169 !important;
    }
  }
}

@media (max-width: 1239px) {
  //1024
  .content-wrapper {
    padding: 34px 15px;
  }
}

@media (max-width: 904px) {
  //991
  main {
    flex-direction: column;
  }
}

.matelex-backgroud {
  position: relative;
  background-color: $general-light-blue-bg;
}
.ng-dropdown-panel {
  margin-top: -16px;
}
.ng-select {
  width: 360px;
  display: inline-block !important;
  margin-right: 16px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-weight: unset;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  color: grey;
}
.checkbox-ng-select .mat-checkbox-label {
  padding: 8px 16px;
}

.chart {
  display: block;
  width: 100%;
}
.mat-form-field-suffix {
  margin-right: 2px;
  margin-left: 2px;
}
